.bulleted-list {

  .bulleted-list-element {

    .bulleted-list-text{
      h1, h2, h3, h4, h5, h6{
        font-size: 1rem !important;
      }
    }

    .bulleted-list-icon {
      svg{
        width:14px;
        height:14px;
        fill: #4b4b4b;
      }
    }
  }
}


