.section-api-request {
  .api-request-box {
    border: 1px solid #ddd;
    margin-bottom: 15px;

    .api-request-box-header {
      display: block;
      color: $body-color;
      padding: 6px 35px 6px 15px;
      background: #eee;
      font-size: 17px;
      position: relative;

      .method {
        opacity: .7;
      }

      .url {
        font-weight: bold;
      }

      .api-request-box-header-icon {
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -12px;
        transition: transform .2s;
      }

      &.collapsed .api-request-box-header-icon {
        transform: rotate(90deg);
      }

      &:hover {
        background: #e9e9e9;
        text-decoration: none;
      }
    }

    .api-request-box-body {
      border-top: 1px solid #ddd;
      padding: 15px 15px 0;
      background: #fff;
    }
  }

  .params-list {
    .params-list-entry {
      padding: 10px 0;
      border-top: 1px solid #ddd;
    }
  }

  .code-box {
    border: 1px solid #ddd;
    margin-bottom: 15px;

    pre {
      margin: 0;
    }

    .code-box-header {
      background: #eee;
      border-bottom: 1px solid #ddd;
      padding: 5px 10px;
      line-height: 30px;

      .method {
      }

      .url {
        font-weight: bold;
      }
    }

    .code-box-body {
      padding: 10px;
      background: #fff;
    }
  }
}
