@media print {
  .page-header {
    display:block !important;
    position:relative !important;
    h1 {
      color: #333;
    }
  }

  .cookie-banner{
    display:none !important;
  }
}
