body.page-body .social-links {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  justify-content:start;
  font-size: 2.4rem;

  li{
    padding: 0 .5rem;
  }

  .social-github:hover {
    color: #1b1b1b;
  }

  .social-twitter:hover {
    color: #3ba0ee;
  }

  .social-linkedin:hover {
    color: #2674af;
  }

  .social-facebook:hover {
    color: #415b95;
  }

  a,
  .fa {
    color: $gray-5;
    display: block;
  }

  .fab:before {
    transition: color .25s;
  }
}

