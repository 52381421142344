.section-plan-compare{
  .table{
    border: 1px solid $primary;
    th,td{
      border:0;
      vertical-align: middle !important;
      .fas{
        font-size:2rem;
      }
    }
  }
}
