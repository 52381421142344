.section-code {
  p{
    margin-bottom:0;
    padding-bottom:0;
  }
  pre{
    border: 1px solid #cccccc;
  }

  button.btn-code-collapse{
    &, &:hover, &:active, &:focus{
      text-decoration: none !important;
    }
    .show-code{
      display:none;
    }
    .hide-code{
      display:block;
    }
    &.collapsed{
      .show-code{
        display:block;
      }
      .hide-code{
        display:none;
      }
    }
  }
}

.hljs{
  padding:1rem !important;
}
