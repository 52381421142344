.cookie-settings-overlay {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1000;

  button {
    border: 0;
    background: $gray-6;
    color: $white;
    width: 26px;
    height: 26px;
    padding: 0;
    font-size: 10px;
    vertical-align: center;
    text-align: center;
    border-top-left-radius: $border-radius;

    &:focus {
      outline: 0;
    }
  }
}

.switch {
  $switch-width: 50px;
  $switch-height: 24px;

  position: relative;
  display: inline-block;
  width: $switch-width;
  height: $switch-height + 16px;
  margin-bottom: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      & + .slider-container {
        .slider {
          background-color: $primary;

          &:before {
            transform: translateX($switch-width - $switch-height);
          }
        }

        .switch-off {
          display: none;
        }

        .switch-on {
          display: block;
        }
      }
    }
  }

  .switch-off,
  .switch-on {
    font-size: .8rem;
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .switch-on {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    height: $switch-height;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;

    &.round {
      border-radius: $switch-height;

      &:before {
        border-radius: 50%;
      }
    }

    &:before {
      position: absolute;
      content: '';
      height: $switch-height - 6px;
      width: $switch-height - 6px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }
}

.cookie-modal {
  .cookie-scroll-text {
    max-height:150px;
    overflow-y: auto;
    border: #dcdcdc 1px solid;
    margin-bottom: $spacer *2;
    padding: $spacer *.5;
    background:#FFF;

    p:last-child{
      margin-bottom: 0;
    }
  }

  .cookie-section-title {
    font-size: 1.3rem;
    font-weight: $font-weight-bold;
  }

  .cookie-list-item {
    border: 1px solid $gray-2;
    padding: $spacer;
    margin-bottom: $spacer;

    table {
      font-size: .9em;
    }
  }
}
