/*
//Include to add Sprites to your CSS File
@import "sprites/sprite-...";
@include sprites($spritesheet-sprites);
//@include retina-sprites($retina-groups); //Include if "enable_retina_sprites" is true
*/
@import "mixins";
@import "base";
@import "related-pages";
@import "cookie-v1";
@import "cookie";
@import "footer";
@import "header";
@import "page";
@import "blocks";
@import "toclist";
@import "search";
@import "social";
@import "fonts";

@each $brand, $data in $brands {
  @if $brand == 'ixolit' {
    body {
      @include page-color($brand, $data);
    }
  } @else {
    body.brand-#{$brand} {
      @include page-color($brand, $data);
    }
  }
}
