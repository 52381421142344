@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/source-sans-pro/SourceSansPro-Bold.eot');
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
  url('../fonts/source-sans-pro/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/source-sans-pro/SourceSansPro-Bold.woff2') format('woff2'),
  url('../fonts/source-sans-pro/SourceSansPro-Bold.woff') format('woff'),
  url('../fonts/source-sans-pro/SourceSansPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/source-sans-pro/SourceSansPro-ExtraLightItalic.eot');
  src: local('Source Sans Pro ExtraLight Italic'), local('SourceSansPro-ExtraLightItalic'),
  url('../fonts/source-sans-pro/SourceSansPro-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/source-sans-pro/SourceSansPro-ExtraLightItalic.woff2') format('woff2'),
  url('../fonts/source-sans-pro/SourceSansPro-ExtraLightItalic.woff') format('woff'),
  url('../fonts/source-sans-pro/SourceSansPro-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/source-sans-pro/SourceSansPro-Light.eot');
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
  url('../fonts/source-sans-pro/SourceSansPro-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/source-sans-pro/SourceSansPro-Light.woff2') format('woff2'),
  url('../fonts/source-sans-pro/SourceSansPro-Light.woff') format('woff'),
  url('../fonts/source-sans-pro/SourceSansPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/source-sans-pro/SourceSansPro-BoldItalic.eot');
  src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'),
  url('../fonts/source-sans-pro/SourceSansPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/source-sans-pro/SourceSansPro-BoldItalic.woff2') format('woff2'),
  url('../fonts/source-sans-pro/SourceSansPro-BoldItalic.woff') format('woff'),
  url('../fonts/source-sans-pro/SourceSansPro-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/source-sans-pro/SourceSansPro-LightItalic.eot');
  src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'),
  url('../fonts/source-sans-pro/SourceSansPro-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/source-sans-pro/SourceSansPro-LightItalic.woff2') format('woff2'),
  url('../fonts/source-sans-pro/SourceSansPro-LightItalic.woff') format('woff'),
  url('../fonts/source-sans-pro/SourceSansPro-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/source-sans-pro/SourceSansPro-Black.eot');
  src: local('Source Sans Pro Black'), local('SourceSansPro-Black'),
  url('../fonts/source-sans-pro/SourceSansPro-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/source-sans-pro/SourceSansPro-Black.woff2') format('woff2'),
  url('../fonts/source-sans-pro/SourceSansPro-Black.woff') format('woff'),
  url('../fonts/source-sans-pro/SourceSansPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/source-sans-pro/SourceSansPro-Regular.eot');
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
  url('../fonts/source-sans-pro/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/source-sans-pro/SourceSansPro-Regular.woff2') format('woff2'),
  url('../fonts/source-sans-pro/SourceSansPro-Regular.woff') format('woff'),
  url('../fonts/source-sans-pro/SourceSansPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/source-sans-pro/SourceSansPro-SemiBold.eot');
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
  url('../fonts/source-sans-pro/SourceSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/source-sans-pro/SourceSansPro-SemiBold.woff2') format('woff2'),
  url('../fonts/source-sans-pro/SourceSansPro-SemiBold.woff') format('woff'),
  url('../fonts/source-sans-pro/SourceSansPro-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/source-sans-pro/SourceSansPro-Italic.eot');
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
  url('../fonts/source-sans-pro/SourceSansPro-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/source-sans-pro/SourceSansPro-Italic.woff2') format('woff2'),
  url('../fonts/source-sans-pro/SourceSansPro-Italic.woff') format('woff'),
  url('../fonts/source-sans-pro/SourceSansPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/source-sans-pro/SourceSansPro-SemiBoldItalic.eot');
  src: local('Source Sans Pro SemiBold Italic'), local('SourceSansPro-SemiBoldItalic'),
  url('../fonts/source-sans-pro/SourceSansPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/source-sans-pro/SourceSansPro-SemiBoldItalic.woff2') format('woff2'),
  url('../fonts/source-sans-pro/SourceSansPro-SemiBoldItalic.woff') format('woff'),
  url('../fonts/source-sans-pro/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/source-sans-pro/SourceSansPro-BlackItalic.eot');
  src: local('Source Sans Pro Black Italic'), local('SourceSansPro-BlackItalic'),
  url('../fonts/source-sans-pro/SourceSansPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/source-sans-pro/SourceSansPro-BlackItalic.woff2') format('woff2'),
  url('../fonts/source-sans-pro/SourceSansPro-BlackItalic.woff') format('woff'),
  url('../fonts/source-sans-pro/SourceSansPro-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/source-sans-pro/SourceSansPro-ExtraLight.eot');
  src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'),
  url('../fonts/source-sans-pro/SourceSansPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/source-sans-pro/SourceSansPro-ExtraLight.woff2') format('woff2'),
  url('../fonts/source-sans-pro/SourceSansPro-ExtraLight.woff') format('woff'),
  url('../fonts/source-sans-pro/SourceSansPro-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

