.related-pages{
  .related-page{
    display:block;
    padding: 1em;
    margin: 0 -1em;
    background: transparent;
    transition: background .3s;
    &:hover, &:focus, &:active {
      background:#FFF;
      text-decoration: none;
      cursor: pointer;
    }

    p{
      font-size:.85rem;
      line-height:1.2em;
      color:$gray-4;
      margin:0;
    }
  }
}
