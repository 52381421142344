.page-body .cookie-banner-v1{
  z-index: 1000;
  background: $gray-6;
  @include gradient-x($gray-6, $gray-5);

  .btn-link {
    color: #FFF;
    font-weight: $font-weight-bold;

    &:hover {
      color: #FFF;
    }
  }


  .cookie-banner-text {
    a {
      text-decoration: underline;
      color: $cookie-banner-link-color;
    }
  }

  @include media-breakpoint-down(md){
    &, .btn, .btn-link{
      font-size: .8rem;
    }
    .btn,
    .btn-link{
      line-height:inherit;
    }
  }
}
