.section-highlighted{
  .card{
    .card-header{
      font-weight:600;
      font-size:1.2rem;

      .fas, svg{
        margin-right:$spacer;
      }

      svg{
        width:15px;
        height:15px;
        margin-top:-4px;
        margin-right:$spacer;
      }
    }

    &.note,
    &.hint{
      border-color: #ffeaae;
      .card-header{
        border-color: #ffeaae;
        background: #ffeaae;
      }
      .card-body{
        border-color: #ffeaae;
        background: #fffdf6;
      }
    }
    &.info{
      border-color: #aab8c6;
      .card-header{
        border-color: #aab8c6;
        background: #aab8c6;
        color:#FFF;
      }
      .card-body{
        border-color: #aab8c6;
        background: #fcfcfc;
      }
    }
    &.full{
      border-color: $primary;
      .card-header{
        border-color: $primary;
        background: $primary;
        color:#FFF;
        svg{
          fill:#FFF;
        }
      }
      .card-body{
        border-color: $primary;
        background: lighten($primary,60%)
      }
    }

    .section-margin:last-child{
      margin-bottom:0;
    }
  }
}

.brand-ixoplan{
  .section-highlighted{
    .card{
      &.full{
        border-color: $ixoplan-dark;
        .card-header{
          border-color: $ixoplan-dark;
          background: $ixoplan-dark;
          color:#FFF;
          svg{
            fill:#FFF;
          }
        }
        .card-body{
          border-color: $ixoplan-dark;
          background: lighten($ixoplan-light,30%);
        }
      }
    }
  }
}
