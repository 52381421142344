@include media-breakpoint-down(sm) {
  .mobile-full-width {
    margin-left: - $grid-gutter-width/2;
    margin-right: - $grid-gutter-width/2;

    &.content-padding{
      padding-left:0;
      padding-right:0;
    }
  }
}
