.page-header {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
  min-height:4rem;
  @extend .bg-primary;
  color:#FFF;
  border-bottom: 1px solid rgba(0, 0, 0, .1);

  .logo{
    width:200px;
    display:block;
  }

  h1{
    color:#FFF;
    font-weight:300;
    font-size:2em;
    padding-top:6px;
    padding-left:5px;
  }
}
