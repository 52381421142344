.search{
  label,input,
  .input-group-text{
    border-width:1px;
  }
  label{
  }
  input{
    transition: background .3s;
    background:transparent;
    &:focus{
      background:#FFF;
      border-color:#e9ecef;
    }
  }

  .search-recommendations{
    display:none;
    background:#FFF;
    padding: 0rem 1rem 0;
    line-height:1rem;
    border: 1px solid #e9ecef;
    border-top:0;

    .search-recommendations-close{
      transition:color .3s;
      color:$gray-4;
      cursor:pointer;
      &:hover,
      &:focus{
        color:$gray-6;
      }
    }

    .search-recommendation-item{
      display: block;
      padding: 1rem;
      margin: 0 -1rem;
      font-weight:bold;
      transition: background .3s, color .3s;
      color:$gray-4;
      &:hover,
      &:focus,
      &:visited{
        text-decoration:none;
        color:$gray-4;
      }
      &:hover,
      &:focus{
        color:$gray-6;
        background:lighten($gray-2,10%);
      }
    }
  }

  &.search-results-container{
    label,input,
    .input-group-text{
      border-width:1px;
    }
    input{
      transition: background .3s;
      background:transparent;
      &:focus{
        background:#FFF;
        border-color:#e9ecef;
      }
    }

    .search-recommendations{
      padding:0;
      justify-content: start;
      .search-recommendation-item{
        margin: 0;
        text-align:center;
      }
    }

    .search-results{
      .search-result-item{
        display:block;
        transition: background .3s;
        margin: 0 -1rem;
        padding:1rem;

        &,&:hover,&:focus,&:active,&:visited {
          color: $gray-4;
        }
        &:hover,&:focus,&:active,&:visited{
          text-decoration:none;
          background:lighten($gray-3,70%);
        }
      }
    }
  }
}

