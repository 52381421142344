#toclist {

  .nav-link {
    position: relative;
    cursor:pointer;
    padding: 0;
    &.collapsed{
      .fas{
        transform:rotate(0deg);
        color: $gray-3 !important;
      }
    }
  }

  .nav-padding{
    padding-top:.5rem;
    padding-bottom:.5rem;
  }

  .nav-item{
    margin: 0 -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    transition:background .3s, color .3s;

    &, a.nav-link{
      color: $gray-3 !important;
    }

    .fas{
      display:inline-block;
      position:relative;
      transition:transform .3s, color .3s;
      transform:rotate(90deg);
      margin-top:2px;
    }

    &, a.nav-link,
    .fas{
      transition: color .3s;
    }


    &.active,
    .nav-link.active{
      @extend .text-primary;
    }
  }

  .nav-link:hover,
  a.nav-link:hover{
    &, .fas{
      color: $gray-5 !important;
    }
  }

  .nav-item-collapse:hover{
    > .nav-link, > a.nav-link{
      &, .fas{
        color: $gray-5 !important;
      }
    }
  }

  .nav-item,
  .nav-item-collapse{

  }

  .collapse{
    background: #EEEEEE;
    transition:background .3s;
    margin: 0 -1rem;
    padding: 0 1rem;
  }

  .toc-list-level-2,
  .toc-list-level-3,
  .toc-list-level-4{
    font-weight:600;
    font-size: 1rem;
    line-height:1rem;
    .fas{
      font-size: 1.2rem;
    }
  }

  .toc-list-level-2{
    padding-left:1rem;
  }
  .toc-list-level-3{
    padding-left:1.75rem;
  }
  .toc-list-level-4{
    padding-left:2rem;
  }

  /*
  @include media-breakpoint-down(sm) {
    padding-bottom: 2rem;
    .toc-list-level-1{
      padding-top:.5rem;
      padding-bottom:.5rem;
    }

    .toc-list-level-2,
    .toc-list-level-3,
    .toc-list-level-4 {
      padding-top:1rem;
      padding-bottom:1rem;
    }
    .toc-list-level-1 {
      &,.fas{
        font-size:1.5rem;
      }
    }
    .toc-list-level-2,
    .toc-list-level-3,
    .toc-list-level-4{
      &,.fas{
        font-size:1.35rem;
      }
    }
  }
   */
}

