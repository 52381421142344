.page-footer {
  padding: 10px 0;
  color: $default-footer-font-color;
  background: $default-footer-background;

  a {
    color: $default-footer-link-color;

    &:hover {
      color: $default-footer-link-color-hover;
    }
  }
}

body.page-footer-bottom {
  position: relative;
  height: auto !important;
  height: 100%;
  min-height: 100%;

  .page-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

@include media-breakpoint-down(sm) {
  body.page-footer-bottom {
    .page-footer,
    .page-footer-push {
      position: static;
      height: auto !important;
    }
  }
}
